import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import Logotipo from '@resources/imgs/logo-proseed-2.png'
import Home from '@resources/imgs/Home.svg'
import Buscar from '@resources/imgs/procurar-buscador.svg'
import Pedidos from '@resources/imgs/Pedidos.svg'
import Favorito from '@resources/imgs/heart.svg'
import Mensagem from '@resources/imgs/Mensagem.svg'
import Reporte from '@resources/imgs/Group-heart.svg'
import FAQ from '@resources/imgs/FAQ.svg'
import Iconlogo from '@resources/imgs/icon-proseed.png'
import PanelContext from '@contexts/Panel'
import { CiUser } from 'react-icons/ci'

const Sidebar: React.FC<any> = () => {
  const { activeMenu } = useContext(PanelContext)
  const [menuOpen, setMenuOpen] = useState(false)

  const isActivated = (path: string) => activeMenu === `/${path}` ? 'activated' : ''

  return (
    <div className={`main-sidebar sidebar-style-2 ${menuOpen ? 'active' : ''}`}>
      <div className={`d-lg-none hamburger ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
        {menuOpen ? '✖' : '☰'}
      </div>
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand mb-3">
          <Link className="link" to="#">
            <img className="logo" src={Logotipo} alt="ProSeed" />
          </Link>
        </div>
        <div className="sidebar-brand sidebar-brand-sm">
          <Link className="link" to="/">
            <img className="iconlogo" src={Iconlogo} alt="ProSeed" />
          </Link>
        </div>
        <ul className="sidebar-menu">
          <li className={isActivated('home')}>
            <Link className="link nav-link" to="/home">
              <img className="pr-2" src={Home} alt="" /> <span>Home</span>
            </Link>
          </li>
          <li className={isActivated('perfil')}>
            <Link className="link nav-link" to="/perfil">
              <CiUser size={35} className="pr-2" /> <span>Perfil</span>
            </Link>
          </li>
          <li className={isActivated('doadores')}>
            <Link className="link nav-link" to="/paciente">
              <img className="pr-2" src={Buscar} alt="" /> <span>Buscar doadores</span>
            </Link>
          </li>
          <li className={isActivated('pedidos')}>
            <Link className="link nav-link" to="/pedidos">
              <img className="pr-2" src={Pedidos} alt="" /> <span>Pedidos</span>
            </Link>
          </li>
          <li className={isActivated('favoritos')}>
            <Link className="link nav-link" to="/favoritos">
              <img className="pr-2" src={Favorito} alt="" /> <span>Favoritos</span>
            </Link>
          </li>
          <li className={isActivated('contato')}>
            <Link className="link nav-link" to="/contato">
              <img className="pr-2" src={Mensagem} alt="" /> <span>Contato</span>
            </Link>
          </li>
          <li className={isActivated('reporte')}>
            <Link className="link nav-link" to="/reporte">
              <img className="pr-2" src={Reporte} alt="" /> <span>Reporte sua gestação</span>
            </Link>
          </li>
          <li className={isActivated('faq')}>
            <Link className="link nav-link" to="#">
              <img className="pr-2" src={FAQ} alt="" /> <span>FAQ</span>
            </Link>
          </li>
        </ul>
      </aside>
    </div>
  )
}

export default Sidebar
