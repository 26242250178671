import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import { Spinner } from 'react-bootstrap'
import LoginContext from '@contexts/Login'
import Corrida from '@resources/imgs/corrida-esperma.png'
import Logoprossed from '@resources/imgs/logo-proseed-1.png'
import SMS from '@resources/imgs/sms.png'
import LOCK from '@resources/imgs/lock.png'
import '@resources/css/login.css'
import { FaEyeSlash, FaEye } from 'react-icons/fa'

type Props = {
  donorId?: string
}

const Login: React.FC<Props> = ({ donorId }) => {
  const { email, setEmail, password, setPassword, isLoading, setIsLoading, error, setError } = useContext(LoginContext)
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.securityCode(email, password).then(() => {
      setError(null)
      const link = donorId ? `/security-code?doador=${donorId}` : '/security-code'
      navigate(link)
    }).catch(e => {
      if (e.response?.data?.message && e.response.data.message === 'E-mail não verificado.') {
        navigate('/verificar-email')
      }

      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar realizar o login. Favor tentar novamente.')
    }).finally(() => setIsLoading(false))
  }

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  return (
    <div className="container">
      <div className="login-modal mb-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="left-side">
              <div style={{ height: '150px' }}>
                <figure>
                  <img src={Corrida} alt="Corrida de Espermas" height={120} />
                </figure>
              </div>
              <div className="text-area">
                <h2>Quero me cadastrar!</h2>
                <p className="mt-0">Cadastre-se para ter <span>acesso gratuito e ilimitado</span> aos perfis completos de +280 doadores nacionais, americanos e europeus à pronta entrega!</p>
              </div>
              <span className="text-center register-doctor">Você é médico? <Link to={`/medico/cadastre-se?doador=${donorId}`}>Cadastre-se aqui!</Link></span>
              <Link className="btn btn-primary mt-3" to={`/cadastre-se?doador=${donorId}`}>Fazer meu cadastro</Link>
            </div>

          </div>
          <div className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-between">
            <div className="logo d-flex align-items-center" style={{ height: '150px' }}>
              <figure>
                <img src={Logoprossed} alt="Logo Prossed" height={60} />
              </figure>
            </div>
            {error && (
              <div className="alert alert-danger" role="alert">
                <strong>Atenção!</strong> {error}
              </div>
            )}

            <form onSubmit={onSubmit} className="lg-w-auto">
              <div className="text-login">
                <h2>Já tenho cadastro! Login:</h2>
              </div>
              <div className="form-floating has-validation">
                <div style={{ height: '130px' }}>
                  <div className="inputs d-flex justify-content-between">
                    <i className="sms-icon"><img src={SMS} alt="" /></i>
                    <input
                      type="email"
                      className={'form-control mb-3 custom-placeholder' + (error ? ' is-invalid' : '')}
                      id="email"
                      placeholder="Insira seu e-mail *"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={isLoading}
                      required
                    />
                  </div>
                  <div className="inputs d-flex justify-content-between">
                    <i className="pw-icon"><img src={LOCK} alt="" /></i>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className={'form-control mb-3 custom-placeholder' + (error ? ' is-invalid' : '')}
                      id="password"
                      placeholder="Insira sua senha *"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isLoading}
                      required
                    />
                    <button
                      type="button"
                      className={`btn eye-icon px-3 ${error ? 'mr-3' : ''}`}
                      onClick={(e) => togglePasswordVisibility(e)}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="closed-eye-icon" />
                      ) : (
                        <FaEye className="open-eye-icon" />
                      )}
                    </button>
                  </div>
                </div>
                <div className="btn-and-lostpw text-right">
                  <Link to="/recuperar-senha">Esqueceu sua senha?</Link>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isLoading}
                  >
                      Acessar {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
