import Self from '@resources/imgs/desenho-de-colagem-de-pessoas-1.png'
import '@resources/fontes/fontes.css'
import '@resources/css/profile.css'
import React, { useContext } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import 'swiper/css/navigation'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import { useNavigate } from 'react-router-dom'
import { BsChevronRight } from 'react-icons/bs'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import AuthContext from '@contexts/Auth'
import moment from 'moment'

const animatedComponents = makeAnimated()

const Profile: React.FC<any> = () => {
  const { user } = useContext(AuthContext)

  const navigate = useNavigate()

  return (
    <div className="profile-page">
      <Sidebar />
      <main className="with-fixed-nav">
        <div className="container">
          <div className="row align-items-center justify-content-between mt-3 mb-3">
            <div className="col-7 col-sm-7 col-md-3 d-none d-md-block">
              <div className="row m-0">
                <div className="col-auto pr-0">
                  <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                    Home
                  </a>
                </div>
                <div className="col-auto pl-1 pr-1">
                  <BsChevronRight size={10} />
                </div>
                <div className="col-auto p-0">
                  <a href="#" className="id d-flex align-items-center font-weight-bold">
                    Perfil
                  </a>
                </div>
              </div>
            </div>
            <div className="col-7 col-sm-7 col-md-3 pl-5">
              <img src={LogoProssed} alt="" className="img-fluid d-block d-md-none" />
            </div>
            <div className="col-5 col-sm-5 col-md-6">
              <Header />
            </div>
          </div>
          <div className="pt-2 pb-2 d-block d-md-none">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Home
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Perfil
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <section className="form-section">
            <div className="photo-area d-flex justify-content-center justify-content-md-start">
              <button className="photo">
                <img className="circle-img" src={Self} alt="" />
              </button>
            </div>

            <div className="form-line">
              <div className="text">
                <h5>Perfil</h5>
                <p>Aqui estão os principais dados de acesso do seu perfil</p>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Nome e sobrenome</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.name}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Data de Nascimento</label>
                    <input
                      className="form-control"
                      type="date"
                      value={moment(user?.birthdate).format('yyyy-MM-DD')}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Celular</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.whatsapp}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border-line form-line">
              <div className="row">
                <div className="col-12 col-sm-3 col-lg-4">
                  <div className="form-group">
                    <label>CPF</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.document}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-3 col-lg-4">
                  <div className="form-group">
                    <label>RG</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.rg}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>E-mail</label>
                    <input
                      className="form-control"
                      type="email"
                      value={user?.email}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border-line form-line">
              <div className="row">
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="form-group">
                    <label>Senha Atual</label>
                    <input className="form-control" type="text" placeholder="Insira a senha atual" />
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="form-group">
                    <label>Nova senha</label>
                    <input className="form-control" type="text" placeholder="Insira a nova" />
                  </div>
                </div>
              </div>

            </div>
            <div className="border-line form-line">
              <div className="text">
                <h5>Endereço</h5>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-2">
                  <div className="form-group">
                    <label>CEP <span>*</span></label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.zipcode}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Endereço <span>*</span></label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.street}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-lg-2">
                  <div className="form-group">
                    <label>Número <span>*</span></label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.number}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Bairro <span>*</span></label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.area}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Cidade <span>*</span></label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.city}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-lg-2">
                  <div className="form-group">
                    <label>Estado <span>*</span></label>
                    <Select
                      className="react-select"
                      placeholder={<div>Selecione</div>}
                      components={animatedComponents}
                      options={[]}
                      isClearable={false}
                      isSearchable={true}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      closeMenuOnSelect={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-6">
                  <div className="form-group">
                    <label>Complemento</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.complement}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-line">
              <div className="text">
                <h5>Dados do Companheiro</h5>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Nome e sobrenome</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.partner_name}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Data de Nascimento</label>
                    <Select
                      className="react-select"
                      placeholder={<div>Selecione</div>}
                      components={animatedComponents}
                      options={[]}
                      isClearable={false}
                      isSearchable={true}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      closeMenuOnSelect={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>Celular</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.partner_phone}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-line">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>CPF</label>
                    <input
                      className="form-control"
                      type="text"
                      value={user?.patient.partner_document}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>RG</label>
                    <input
                      className="form-control"
                      type="text"
                      value=""
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="form-group">
                    <label>E-mail</label>
                    <input
                      className="form-control"
                      type="email"
                      value={user?.patient.partner_email}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="footer d-none">
            <div className="d-flex align-items-center justify-content-end">
              <div className="price-btn d-flex align-items-center">
                <button className="register-btn btn btn-primary">Realizar Solicitação</button>
              </div>
            </div>
          </section>

        </div>
      </main>
    </div>
  )
}

export default Profile
