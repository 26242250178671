import React, { useContext, useEffect, useState } from 'react'
import * as $Auth from '@services/Auth'
import { Spinner } from 'react-bootstrap'
import LoginContext from '@contexts/Login'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FaEnvelope } from 'react-icons/fa'

const VerifyEmail: React.FC<any> = () => {
  const { email } = useContext(LoginContext)

  const [ isLoading, setIsLoading ] = useState(false)
  const [ isSent, setIsSent ] = useState(false)
  const [ error, setError ] = useState<string|null>(null)

  const [ searchParams ] = useSearchParams()

  const navigate = useNavigate()

  useEffect(() => {
    if (searchParams.has('id') && searchParams.has('hash')) {
      const email = searchParams.get('email')
      const id = searchParams.get('id')
      const hash = searchParams.get('hash')

      if (email && id && hash) {
        $Auth.verifyEmail(email, id, hash).then(() => {
          setError(null)
          navigate('/?login=1&verified=1')
        }).catch(e => {
          setError(e.response?.data?.message || 'Ocorreu um erro ao tentar a verificação de e-mail. Favor tentar novamente.')
        })
      }
    }
  }, [ searchParams, navigate ])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    if (!email) {
      return navigate('/?login=1')
    }

    $Auth.resendVerifyEmail(email).then(() => {
      setError(null)
      setIsSent(true)
    }).catch(e => {
      setIsSent(false)
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar a recuperação de senha. Favor tentar novamente.')
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="row">
      <div className="col-12 col-md-8 offset-md-2">
        <div className="card card-full">
          <div className="card-header d-flex flex-column align-items-center pt-3">
            <figure>
              <FaEnvelope size={120} style={{ color: '#FFA9BF' }} />
            </figure>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card-body d-flex flex-column align-items-center">
              {isSent && (
                <div className="alert alert-success" role="alert">
                  E-mail de verificação reenviado com sucesso.
                </div>
              )}

              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}

              <h3 className="mt-3">
                Verificação de e-mail
              </h3>

              <p>Confira sua caixa de entrada ou spam e siga as instruções para a verificação do seu e-mail.</p>
            </div>

            <div className="card-footer d-flex flex-column align-items-center">
              <button type="submit" className="btn btn-primary send-btn btn-block d-flex align-items-center justify-content-center" disabled={isLoading}>
                <span>Renviar e-mail de verificação</span> {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
