/* eslint-disable react/jsx-curly-newline */
import React, { forwardRef, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Username } from './Header.styles'
import Dropdown from 'react-bootstrap/Dropdown'
import AuthContext from '@contexts/Auth'
import Avatar from '@resources/imgs/mask-woman.jpg'
import '@components/Header/header.css'
import { CiLogout, CiUser } from 'react-icons/ci'

const Item = forwardRef(({ children, onClick, }: any, ref: any) => (
  <Button
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >{children}</Button>
))

Item.displayName = 'Item'

const Header: React.FC<any> = () => {
  const { user, isLoading, logout } = useContext(AuthContext)

  const navigate = useNavigate()

  if (!isLoading && !user) {
    navigate('/')
  }

  return (
    <>
      <div className="navbar-bg"></div>
      <nav className="navbar navbar-expand-lg main-navbar d-flex justify-content-end pb-0 pr-0">
        <ul className="navbar-nav navbar-right d-flex align-items-center">
          <li className="dropdown">
            <Dropdown>
              <Dropdown.Toggle as={Item} variant="primary">
                <img alt="image" src={Avatar} style={{ maxWidth: 40 }} className="rounded-circle mx-2"/>
                <Username className="d-none d-md-block text-end">
                  {user?.name}
                </Username>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link to="/perfil" className="dropdown-item has-icon">
                  <CiUser /> Perfil
                </Link>

                <a href="#" className="dropdown-item has-icon text-danger" onClick={logout}>
                  <CiLogout /> Sair
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default Header
