import React, { useState, MouseEvent, useContext, useRef, useEffect } from 'react'
import Donators from '@components/Donator/Donators'
import Poligono from '../../resources/imgs/polygon-1.svg'
import '@resources/fontes/fontes.css'
import 'swiper/css/navigation'
import CatalogFooter from '@components/CatalogFooter/CatalogFooter'
import CatalogHeader from '@components/CatalogHeader/CatalogHeader'
import { Modal } from 'react-bootstrap'
import ModalFilters from '@components/ModalFilters/ModalFilters'
import { FaTimes } from 'react-icons/fa'
import { GoChevronLeft, GoChevronRight } from 'react-icons/go'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { IoIosSearch } from 'react-icons/io'
import '@resources/css/catalogo/home.css'
import { LuSettings2 } from 'react-icons/lu'
import DonorsContext from '@contexts/Donors'
import { useSearchParams } from 'react-router-dom'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Home: React.FC<any> = () => {
  const {
    donors,
    pagination,
    isLoading,
    options,
    filters,
    setFilters,
    handleApplyFilters,
    handleClearFilters,
    handleClearSelectedFilters,
    handleNextPage
  } = useContext(DonorsContext)

  const [showModal, setShowModal] = useState<{ show: boolean, title: string, filterType: string }>({ show: false, title: '', filterType: '' })

  const [ searchParams ] = useSearchParams()

  const [openCharacter, setOpenCharacter] = useState(false)

  const searchButtonRef = useRef<HTMLButtonElement>(null)
  const bottomRef = useRef<HTMLDivElement>(null)

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (searchParams.has('verified') && searchParams.get('verified') === '1') {
      SweetAlert.fire({
        title: 'E-mail verificado com sucesso!',
        icon: 'success',
        confirmButtonText: 'OK'
      })
    }
  }, [searchParams, SweetAlert])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      searchButtonRef.current?.click()
    }
  }

  const handleCharacter = () => {
    setOpenCharacter(!openCharacter)
  }

  const handleShowModal = (title: string, filterType: string) => {
    setShowModal({ show: true, title, filterType })
  }

  const handleCloseModal = () => {
    showModal.filterType === 'spermBank' && setFilters({ ...filters, spermBank: [] })
    setShowModal({ show: false, title: '', filterType: '' })
  }

  const clearFilters = (filterType: string) => {
    handleClearSelectedFilters(filterType)
    handleCloseModal()
  }

  const handleFilter = () => {
    handleApplyFilters()
    setShowModal({ show: false, title: '', filterType: '' })
  }

  const handleRightClick = (event: MouseEvent<HTMLDivElement>) => {
    const parentElement = event.currentTarget.closest('.row')?.querySelector('.box-filter')
    if (parentElement) {
      parentElement.scrollLeft += 100 // Ajuste a distância de rolagem conforme necessário
    }
  }

  const handleLeftClick = (event: MouseEvent<HTMLDivElement>) => {
    const parentElement = event.currentTarget.closest('.row')?.querySelector('.box-filter')
    if (parentElement) {
      parentElement.scrollLeft -= 100 // Ajuste a distância de rolagem conforme necessário
    }
  }

  const getFilterText = (key: string) => {
    return options[key].filter((option: any) => filters[key].includes(String(option.key ?? option)))?.map((option: any) => option.value ?? option).join(', ') || ''
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        handleNextPage()
      }
    }, { threshold: 1.0 })

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(bottomRef.current)
      }
    }
  }, [bottomRef, handleNextPage])

  return (
    <>
      <CatalogHeader />

      <LoadingSpinner isLoading={isLoading} text="Buscando doadores..." />

      <div className="home-page">

        <section className="top-box">
          <div className="container">
            <div className="row position-relative">
              <div className="text">
                <h4 className="m-0">Encontre o seu doador de sêmen</h4>
                <p className="m-0">Explore o maior e mais completo catálogo de doadores nacionais e importados do Brasil!</p>
              </div>
              <div className="polygon-wrapper">
                <img className="polygon" alt="Polygon" src={Poligono} />
              </div>
            </div>
          </div>
        </section>

        <div className="container mt-5">
          <div className="row justify-content-between align-items-center no-gutters">
            <div className="p-0 col-md-12 position-relative">
              {/* <div className="prevtBtn" onClick={handleLeftClick}><GoChevronLeft /></div> */}
              {/* <div className="nextBtn" onClick={handleRightClick}><GoChevronRight /></div> */}
              <div className="box-filter pt-2 pb-2">
                <button className="font-weight-bold btn-all btn btn-primary" onClick={() => handleShowModal('Todos os Filtros', 'all')}><LuSettings2 size={20} className="mr-2"/>Todos os Filtros</button>
                <button className="btn font-weight-bold" onClick={() => handleShowModal('Tratamentos', 'treatmentTypes')}>Tratamentos
                  {searchParams.get('treatmentTypes') && (<span className="number">{searchParams.get('treatmentTypes')?.split(',').length}</span>)}
                </button>
                <button className={`btn font-weight-bold ${openCharacter ? 'btn-primary' : ''}`} onClick={handleCharacter}>Características {openCharacter ? <IoChevronUp /> : <IoChevronDown />}</button>
                <button className="btn font-weight-bold" onClick={() => handleShowModal('Bancos de Origem', 'spermBanks')}>Bancos de Origem
                  {searchParams.get('spermBanks') && (<span className="number">{searchParams.get('spermBanks')?.split(',').length}</span>)}
                </button>
                <button className="btn font-weight-bold" onClick={() => handleShowModal('Tipo de Doador', 'donorTypes')}>Tipo de Doador
                  {searchParams.get('donorTypes') && (<span className="number">{searchParams.get('donorTypes')?.split(',').length}</span>)}
                </button>
                <div className="form-group mb-0 position-relative w-100">
                  <input
                    type="number"
                    onKeyDown={handleKeyDown}
                    className="min-width-input form-control w-100"
                    placeholder="Código do Doador"
                    value={filters?.internalId}
                    onChange={(e) => setFilters({ ...filters, internalId: e.target.value })}
                  />
                  <button className="search-button mr-4" ref={searchButtonRef} onClick={() => handleApplyFilters()}>
                    <IoIosSearch />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={`mt-3 row position-relative ${openCharacter ? '' : 'd-none'}`}>
            <div className="prevtBtn" onClick={handleLeftClick}><GoChevronLeft /></div>
            <div className="nextBtn" onClick={handleRightClick}><GoChevronRight /></div>
            <div className="bt-1 box-filter col-md-12 pt-4 pt-2 pb-2">
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Cor da Pele', 'skinColors')}>Cor da Pele
                {searchParams.get('skinColors') && (<span className="number">{searchParams.get('skinColors')?.split(',').length}</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Cor dos Olhos', 'eyeColors')}>Cor dos Olhos
                {searchParams.get('eyeColors') && (<span className="number">{searchParams.get('eyeColors')?.split(',').length}</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Cor do Cabelo', 'hairColors')}>Cor do Cabelo
                {searchParams.get('hairColors') && (<span className="number">{searchParams.get('hairColors')?.split(',').length}</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Raça', 'races')}>Raça
                {searchParams.get('races') && (<span className="number">{searchParams.get('races')?.split(',').length}</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Tipo Sanguíneo', 'bloodGroups')}>Tipo Sanguíneo
                {searchParams.get('bloodGroups') && (<span className="number">{searchParams.get('bloodGroups')?.split(',').length}</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Mapeamento Genético', 'geneticMappings')}>Mapeamento Genético
                {searchParams.get('geneticMappings') && (<span className="number">1</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Doadores Novos', 'newDonors')}>Doadores Novos
                {searchParams.get('newDonors') && (<span className="number">1</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Altura', 'height')}>Altura
                {filters?.heightMin > 0 && (<span className="number">1</span>)}
              </button>
              <button className="font-weight-bold btn" onClick={() => handleShowModal('Peso', 'weight')}>Peso
                {filters?.weightMin > 0 && (<span className="number">1</span>)}
              </button>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-12 d-flex align-items-center">
              <h6 className="mb-0 text-primary-especial">{pagination.total} Doadores Encontrados</h6>
            </div>
          </div>
          <div className="row mt-3">
            <div className="box-filter current-filters col-md-12 mb-4">
              {Array.from(searchParams.keys()).map((key) => {
                if (filters[key]?.length === 0 || key === 'hasFilter' || key === 'heightMax' || key === 'weightMax') return null
                let title
                let text = ''

                switch (key) {
                case 'spermBanks':
                  title = 'Bancos de Origem'
                  text = getFilterText(key)
                  break
                case 'treatmentTypes':
                  title = 'Tratamentos'
                  text = getFilterText(key)
                  break
                case 'skinColors':
                  title = 'Cor da Pele'
                  text = getFilterText(key)
                  break
                case 'faceShapes':
                  title = 'Tipo de Rosto'
                  text = getFilterText(key)
                  break
                case 'eyeColors':
                  title = 'Cor dos Olhos'
                  text = getFilterText(key)
                  break
                case 'distanceBetweenEyes':
                  title = 'Distância entre os olhos'
                  text = getFilterText(key)
                  break
                case 'hairColors':
                  title = 'Cor do Cabelo'
                  text = getFilterText(key)
                  break
                case 'hairTypes':
                  title = 'Cabelo'
                  text = getFilterText(key)
                  break
                case 'races':
                  title = 'Raça'
                  text = getFilterText(key)
                  break
                case 'bloodGroups':
                  title = 'Tipo Sanguíneo'
                  text = getFilterText(key)
                  break
                case 'eyebrows':
                  title = 'Espessura das Sobrancelhas'
                  text = getFilterText(key)
                  break
                case 'donorTypes':
                  title = 'Tipo de Doador'
                  text = getFilterText(key)
                  break
                case 'geneticMappings':
                  title = 'Mapeamento Genético'
                  text = 'Sim'
                  break
                case 'newDonors':
                  title = 'Doadores Novos'
                  text = 'Sim'
                  break
                case 'heightMin':
                  title = 'Altura'
                  text = `${filters['heightMin']}cm - ${filters['heightMax']}cm`
                  break
                case 'weightMin':
                  title = 'Peso'
                  text = `${filters['weightMin']}kg - ${filters['weightMax']}kg`
                  break
                case 'noseShapes':
                  title = 'Tipos de Nariz'
                  text = filters[key].map((item) => item).join(', ')
                  break
                case 'internalId':
                  title = 'Código do Doador'
                  text = filters[key]
                  break
                }

                if (filters[key] > 0 && (key == 'heightMin' || key == 'weightMin')) {
                  return (
                    <button className="btn" key={key}>
                      <strong>{title}:</strong> {filters[key] ? text : ''} <FaTimes size={10} onClick={() => clearFilters(key)} />
                    </button>
                  )
                }

                if (key != 'heightMin' && key != 'weightMax' && key != 'page' && key != 'login' && key != 'verified') {
                  return (
                    <button className="btn" key={key}>
                      {title}: {filters[key] ? text : ''} <FaTimes size={10} onClick={() => clearFilters(key)} />
                    </button>
                  )
                }
              })}
              {filters.hasFilter && <button type="button" className="btn btn-warning" onClick={handleClearFilters}>Limpar filtros</button>}
            </div>
          </div>

          <div className="donators">
            {!isLoading && (donors?.length > 0 ? donors?.map((donor) => (
              <Donators key={donor.id} donor={donor} />
            )) : (
              <div className="row mt-5">
                <div className="col-12 text-center">
                  <h6>Nenhum doador encontrado</h6>
                </div>
              </div>
            ))}
          </div>
          <div ref={bottomRef} />
        </div>
        <Modal
          show={showModal.show}
          onHide={handleCloseModal}
          size="xl"
          dialogClassName="filter-modal"
        >
          <Modal.Header className="border-0 text-left">
            {showModal.title}
            <button type="button" className="close" onClick={handleCloseModal}>
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="filters-scroll">
              <ModalFilters filterType={showModal.filterType} />
            </div>
            <div id="apply-filter">
              <div className="container-fluid">
                <div className="row justify-content-center align-items-stretch">
                  <div className="col-6 p-2">
                    <button className="btn btn-outline-primary h-100 w-100 p-2" onClick={() => clearFilters(showModal.filterType)}>Limpar</button>
                  </div>
                  <div className="col-6 p-2">
                    <button className="btn btn-primary w-100 p-2" onClick={handleFilter}>Aplicar Filtros</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <CatalogFooter />
    </>
  )
}

export default Home
