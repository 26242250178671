import React, { createContext, useEffect, useState } from 'react'
import { Outlet, useSearchParams, } from 'react-router-dom'
import IDonor from '@interfaces/IDonor'
import IPagination from '@interfaces/IPagination'
import * as $Donor from '@services/Donor'
import { deleteAllSearchParams, formDataReduce, setEntriesToSearchParams } from '@helpers/Filters'
import { IFilter } from '@interfaces/IFilter'

interface IMinMaxValues {
  min_height: number
  max_height: number
  min_weight: number
  max_weight: number
}

type DonorsContextProps = {
  isLoading: boolean
  donors: IDonor[]
  pagination: IPagination
  searchDonors: (searchParams: URLSearchParams) => void
  setPage: (page: number) => void
  options: IFilter
  setOptions: React.Dispatch<React.SetStateAction<IFilter>>
  filters: IFilter
  setFilters: React.Dispatch<React.SetStateAction<IFilter>>
  selectedFilters: IFilter
  setSelectedFilters: React.Dispatch<React.SetStateAction<IFilter>>
  minMaxValues: IMinMaxValues
  handleOnSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  handleNextPage: () => void
  handleApplyFilters: () => void
  handleClearFilters: () => void
  handleClearSelectedFilters: (filterType?: string) => void
}

const DonorsContext = createContext<DonorsContextProps>({} as DonorsContextProps)

export const DonorsProvider: React.FC = () => {
  const [ donors, setDonors ] = useState<IDonor[]>([])

  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ pagination, setPagination ] = useState<IPagination>({
    per_page: 15,
    current_page: 1,
    last_page: 1,
    total: 0,
  })

  const filterDefaultObject = {
    hasFilter: false,

    internalId: '',

    skinColors: [],
    exposedToSunSkinColors: [],
    faceShapes: [],
    noseShapes: [],
    spermBanks: [],
    treatmentTypes: [],
    eyeColors: [],
    eyebrows: [],
    distanceBetweenEyes: [],
    hairTypes: [],
    hairColors: [],
    races: [],
    bloodGroups: [],
    boneStructures: [],
    eyeShapes: [],
    lips: [],
    teethTypes: [],
    particularities: [],
    donorTypes: [],
    geneticMappings: [],
    newDonors: [],
    heightMin: 0,
    heightMax: 0,
    weightMin: 0,
    weightMax: 0,
  }

  const [options, setOptions] = useState<IFilter>(filterDefaultObject)

  const [filters, setFilters] = useState<IFilter>(filterDefaultObject)
  const [selectedFilters, setSelectedFilters] = useState<IFilter>(filterDefaultObject)
  const [ minMaxValues, setMinMaxValues ] = useState<IMinMaxValues>({} as IMinMaxValues)
  const [isFetchingNextPage, setIsFetchingNextPage] = useState<boolean>(false)

  const [ searchParams, setSearchParams ] = useSearchParams()

  const searchDonors = (searchParams: URLSearchParams, append = false) => {
    setSearchParams(searchParams)
    setIsLoading(true)

    $Donor.all(searchParams).then(({ data: { data: newDonors, ...pagination } }: any) => {
      setDonors(prevDonors => append ? [...prevDonors, ...newDonors] : newDonors)
      setPagination(pagination)
    }).finally(() => {
      setIsLoading(false)
      setIsFetchingNextPage(false)
    })
  }

  const setPage = (page: number) => {
    searchParams.set('page', page.toString())
    searchDonors(searchParams, true)
  }

  const checkIfHasFilters = (filters: IFilter) => {
    return Object.keys(filters).some(key => {
      if (key === 'hasFilter' || key === 'page' || key === 'login' || key === 'verified') return false

      if (Array.isArray(filters[key])) {
        return filters[key].length > 0
      }

      if (typeof filters[key] === 'object') {
        return filters[key].minValue > 0 || filters[key].maxValue > 0
      }

      return filters[key] !== '' && filters[key] !== 0 && filters[key] !== '0'
    })
  }

  useEffect(() => {
    searchDonors(searchParams)

    setIsLoading(true)

    $Donor.characteristics().then(({ data }) => {
      const cb = ({ id, name }: any) => ({ key: id, value: name })

      const { min_height, max_height, min_weight, max_weight } = data

      setMinMaxValues({
        min_height,
        max_height,
        min_weight,
        max_weight,
      })

      const skinColors = data.skin_colors.map(cb)
      const exposedToSunSkinColors = data.exposed_to_sun_skin_colors.map(cb)
      const faceShapes = data.face_shapes.map(cb)
      const noseShapes = data.nose_shapes.map(cb)
      const spermBanks = data.sperm_banks.map(cb)
      const treatmentTypes = data.treatment_types.map(({ name }: any) => ({ key: String(name).toLowerCase(), value: name }))
      const eyeColors = data.eye_colors.map(cb)
      const eyebrows = data.eyebrows.map(cb)
      const hairTypes = data.hair_types.map(cb)
      const hairColors = data.hair_colors.map(cb)
      const races = data.races.map(cb)
      const bloodGroups = data.blood_groups.map(cb)
      const boneStructures = data.bone_structures.map(cb)
      const eyeShapes = data.eye_shapes.map(cb)
      const lips = data.lips.map(cb)
      const teethTypes = data.teeth_types.map(cb)
      const distanceBetweenEyes = data.distance_between_eyes.map(cb)
      const particularities = data.particularities.map(cb)
      const donorTypes = ['ID Release', 'No ID Release']
      const geneticMappings = ['genetic-mapping']
      const newDonors = ['new-donors']

      setOptions({
        hasFilter: checkIfHasFilters(filters),
        internalId: '',
        skinColors,
        exposedToSunSkinColors,
        faceShapes,
        noseShapes,
        spermBanks,
        treatmentTypes,
        eyeColors,
        eyebrows,
        distanceBetweenEyes,
        hairTypes,
        hairColors,
        races,
        bloodGroups,
        boneStructures,
        eyeShapes,
        lips,
        teethTypes,
        particularities,
        donorTypes,
        geneticMappings,
        newDonors,
        heightMin: 0,
        heightMax: 0,
        weightMin: 0,
        weightMax: 0,
      })
    })

    const data: any = Object.fromEntries(searchParams)

    Object.keys(data).forEach(key => {
      if (data[key].includes(',')) {
        data[key] = data[key].split(',')
      }

      if (!Array.isArray(data[key])) {
        data[key] = [data[key]]
      }
    })

    const hasFilter = checkIfHasFilters(data)

    data.hasFilter = hasFilter

    setFilters(prev => ({ ...prev, ...data }))
    setSelectedFilters(prev => ({ ...prev, ...data }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedFilters(filters)
  }, [filters])

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const data = formDataReduce(new FormData(e.currentTarget))

    setEntriesToSearchParams(searchParams, data)

    searchDonors(searchParams)
  }

  const handleNextPage = () => {
    if (!isLoading && !isFetchingNextPage && pagination.current_page < pagination.last_page) {
      setIsFetchingNextPage(true)
      setPage(pagination.current_page + 1)
      setTimeout(() => {
        setIsFetchingNextPage(false)
      }, 2000)
    }
  }

  const handleApplyFilters = (payload?: IFilter) => {
    const filtersToApply = payload || selectedFilters

    const hasFilter = checkIfHasFilters(filtersToApply)

    const filtersData = {
      ...filtersToApply,
      hasFilter,
    }

    setFilters(filtersData)

    setEntriesToSearchParams(searchParams, filtersData)

    searchDonors(searchParams)
  }

  const handleClearFilters = () => {
    setFilters(filterDefaultObject)

    deleteAllSearchParams(searchParams)

    searchDonors(searchParams)
  }

  const handleClearSelectedFilters = (filterType?: string) => {
    if (filterType) {
      let filtersData = { ...selectedFilters }

      if (filterType === 'all') {
        return handleClearFilters()
      } else if (filterType === 'heightMin') {
        filtersData = {
          ...selectedFilters,
          heightMin: 0,
          heightMax: 0,
        }
      } else if (filterType === 'weightMin') {
        filtersData = {
          ...selectedFilters,
          weightMin: 0,
          weightMax: 0,
        }
      } else {
        filtersData = {
          ...selectedFilters,
          [filterType]: []
        }
      }

      filtersData = {
        ...filtersData,
        hasFilter: checkIfHasFilters(filtersData)
      }

      setSelectedFilters(filtersData)

      handleApplyFilters(filtersData)
    } else {
      setSelectedFilters(filters)
    }
  }

  return (
    <DonorsContext.Provider
      value={{
        isLoading,
        donors,
        pagination,
        searchDonors,
        setPage,
        options,
        setOptions,
        filters,
        setFilters,
        selectedFilters,
        setSelectedFilters,
        minMaxValues,
        handleOnSubmit,
        handleNextPage,
        handleApplyFilters,
        handleClearFilters,
        handleClearSelectedFilters,
      }}
    >
      <Outlet />
    </DonorsContext.Provider>

  )
}

export default DonorsContext
