import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from '@layouts/App/App'
import Home from '@screens/Home/Home'
import Donator from '@screens/Donator/Donator'
import Login from '@screens/Auth/Login/Login'
import { CompareResultsProvider } from '@contexts/CompareResults'
import ForgotPassword from '@screens/Auth/ForgotPassword/ForgotPassword'
import ResetPassword from '@screens/Auth/ResetPassword/ResetPassword'
import SecurityCode from '@screens/Auth/SecurityCode/SecurityCode'
import { LoginProvider } from '@contexts/Login'
import Register from '@screens/Auth/Register/Register'
import Patient from '@screens/Authenticated/Patient'
import Request from '@screens/Authenticated/Request'
import Sample from '@screens/Authenticated/Sample'
import Profile from '@screens/Authenticated/Profile'
import Order from '@screens/Authenticated/Orders/Order'
import HomeUser from '@screens/Authenticated/HomeUser'
import Contact from '@screens/Authenticated/Contact'
import Report from '@screens/Authenticated/Report'
import Favorites from '@screens/Authenticated/Favorites'
import { AuthProvider } from '@contexts/Auth'
import { DonorsProvider } from '@contexts/Donors'
import VerifyEmail from '@screens/Auth/VerifyEmail/VerifyEmail'
import RegisterDoctor from '@screens/Auth/RegisterDoctor/RegisterDoctor'

const Router: React.FC<any> = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthProvider />}>
          <Route element={<LoginProvider />}>
            <Route element={<App />}>
              <Route element={<CompareResultsProvider />}>
                <Route element={<DonorsProvider />}>
                  <Route index element={<Home />} />
                  <Route path="doadores/:id" element={<Donator />} />
                  <Route path="paciente" element={<Patient />} />
                  <Route path="solicitacao" element={<Request />} />
                  <Route path="pedidos" element={<Order />} />
                  <Route path="amostra" element={<Sample />} />
                  <Route path="perfil" element={<Profile />} />
                  <Route path="home" element={<HomeUser />} />
                  <Route path="contato" element={<Contact />} />
                  <Route path="reporte" element={<Report />} />
                  <Route path="favoritos" element={<Favorites />} />
                </Route>
              </Route>

              <Route path="login" element={<Login />} />
              <Route path="security-code" element={<SecurityCode />} />
              <Route path="verificar-email" element={<VerifyEmail />} />
              <Route path="cadastre-se" element={<Register />} />
              <Route path="medico/cadastre-se" element={<RegisterDoctor />} />

              <Route path="recuperar-senha" element={<ForgotPassword />} />
              <Route path="redefinir-senha" element={<ResetPassword />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
