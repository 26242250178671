import React from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Corrida from './../../resources/imgs/Pro-Seed-Corrida-dos-Espermas-.png'
import '@resources/css/loginsucessmodal.css'

const LoginSuccessModal: React.FC<any> = ({ showModal, handleCloseModal, donorId }) => {

  const link = donorId ? `/doadores/${donorId?.toLowerCase()}` : '/'

  return (
    <>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Body>
          <figure>
            <img src={Corrida} alt="" />
          </figure>
          <h5>Agora você tem acesso total ao nosso portal de busca de doadores à pronta entrega! Aproveite!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Link to={link} className="btn-obg btn btn-primary">Acessar portal de doadores!</Link>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default LoginSuccessModal
